<template>
  <section id="workflow-users-list">
    <div class="p-2 mr-2 d-flex align-items-center justify-end filter-container" v-if="!getIsMobile">
      <div class="filter-item"
        v-if="
        (componentData.formbuilder_filter === 'ALL' || componentData.formbuilder_filter.includes('FORM'))"
      >
        <el-select
          v-model="approvalFilter"
          @change="fetchAllEmployee"
          placeholder="Filter by Approval Status"
          size="small"
          clearable
          multiple
          collapse-tags
        >
          <el-option :label="approvalHoldName ? approvalHoldName.toUpperCase() : 'HOLD'" value="HOLD"></el-option>
          <el-option label="APPROVED" value="APPROVED"></el-option>
          <el-option label="REJECTED" value="REJECTED"></el-option>
          <el-option label="PENDING" value="PENDING"></el-option>
        </el-select>
      </div>

      <div class="filter-item ml-1">
        <el-select
          v-model="sentStatusFilter"
          @change="onSentStatusChange"
          placeholder="Filter by Sent Status"
          size="small"
          clearable
        >
          <el-option label="Today" value="today"></el-option>
          <el-option label="Yesterday" value="yesterday"></el-option>
          <el-option label="This Week" value="this_week"></el-option>
          <el-option label="Last Week" value="last_week"></el-option>
          <el-option label="This Month" value="this_month"></el-option>
          <el-option label="Last Month" value="last_month"></el-option>
          <el-option label="This Year" value="this_year"></el-option>
          <el-option label="Last Year" value="last_year"></el-option>
          <el-option label="Custom Date Range" value="custom_date_range"></el-option>
        </el-select>
      </div>

      <div class="filter-item" v-if="sentStatusFilter == 'custom_date_range'">
        <el-date-picker
          v-model="customDateRange"
          type="daterange"
          format="yyyy-MM-dd"
          start-placeholder="Start date"
          end-placeholder="End date"
          @change="onDateRangeChange"
        />
      </div>

      <div class="filter-item ml-1">
        <el-input
          v-model="searchQuery"
          prefix-icon="el-icon-search"
          placeholder="Search"
          clearable
          @input="fetchAllEmployee"
          size="small"
        ></el-input>
      </div>
    </div>
    <div v-if="selectedRowsIds.length > 0" class="ml-3">
      <el-dropdown>
          <button class="btn btn-primary btn-sm m-lr-1 p-2 rounded" type="button">
            <i class="fa fa-cogs"></i> Bulk Actions
          </button>
          <el-dropdown-menu slot="dropdown">
            <template>
                <a @click="approveAllChecked">
                  <el-dropdown-item command="approve">
                    <i class="fa fa-check"></i> Approve
                  </el-dropdown-item>
                </a>
                <a @click="holdAllChecked">
                  <el-dropdown-item command="approve">
                    <i class="fa fa-hourglass-half"></i> {{approvalHoldName}}
                  </el-dropdown-item>
                </a>
                <a @click="showBulkRejectConfirm">
                  <el-dropdown-item command="reject">
                    <i class="fa fa-times"></i> Reject
                  </el-dropdown-item>
                </a>
            </template>
          </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="vue-data-table-default p-4">
      <data-tables-server
        :data="data"
        :total="total"
        :current-page.sync="currentPage"
        :page-size="pageSize"
        :pagination-props="paginationProps"
        @query-change="loadData"
        v-loading="loading || resendLoading"
        element-loading-spinner="el-icon-loading"
        :element-loading-text="
            resendLoading
              ? 'Sending the form, please wait...'
              : loadingText
          "
        @selection-change="handleSelectionChange"
      >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column label="Form Name" width="180" fixed="left" class="ml-1">
            <template slot-scope="scope">
              <el-tooltip 
                 v-if="
                  scope.row &&
                  scope.row.formbuilders_details_id &&
                  scope.row.formbuilders_details_id.name
                "
                :content="scope.row.formbuilders_details_id.name" 
                placement="top"
                >
                  <div
                    style="word-break: break-word; padding-top: 12px; padding-bottom: 12px;"
                  >
                    {{ scope.row.formbuilders_details_id.name | truncate(20, "...") }}
                  </div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="User email / User IP Address" min-width="240">
            <template slot-scope="scope">
              <div class="d-flex" v-if="!isAnonymousForm(scope.row)">
                <div>{{ getUserEmail(scope.row) }}</div>
              </div>
              <div v-else>
                <div>{{ scope.row.ip_address }}</div>
              </div>
            </template>
          </el-table-column>
        <el-table-column label="Enforce Order Status" min-width="240">
          <template slot-scope="scope">
            <div
              v-if="
                  scope.row.formbuilder_data_status == 'EXPIRED' &&
                  scope.row.next_user_data
                "
              class="log-expired"
            >
              <i class="el-icon-timer"></i>
              Expired at
              {{ getUserName(scope.row) }}
            </div>
            <div v-else-if="automationLogs[scope.row.automation_batch_data_id]">
              <div
                class="success d-flex"
                v-if="
                    automationLogs[scope.row.automation_batch_data_id].status ==
                    'COMPLETED'
                  "
              >
                <div>
                  <i class="el-icon-circle-check"></i>
                  <span class="d-inline-block pl-10">Completed</span>
                </div>&nbsp;
                <automationFormbuilderLogs
                  class="success"
                  :currentFormbuilderData="scope.row"
                  :allEntitiesData="allEntitiesData"
                  :allCompanyUserTypesData="allCompanyUserTypesData"
                  :usersLogs="
                      automationLogs[scope.row.automation_batch_data_id].logs
                    "
                ></automationFormbuilderLogs>
              </div>
              <div
                class="warning d-flex"
                v-else-if="
                    automationLogs[scope.row.automation_batch_data_id]
                      .currentUser
                  "
              >
                <div>
                  <i class="el-icon-time"></i>
                  <span class="d-inline-block pl-10">
                    Waiting for
                    {{
                    automationLogs[scope.row.automation_batch_data_id]
                    .currentUser.user_name ||
                    automationLogs[scope.row.automation_batch_data_id]
                    .currentUser.user_email ||
                    "others"
                    }}
                    <!-- {{'Waiting for '+automationLogs[scope.row.automation_batch_data_id].currentUser.user_name || automationLogs[scope.row.automation_batch_data_id].currentUser.user_email || "Waiting for others"}} -->
                  </span>
                </div>&nbsp;
                <automationFormbuilderLogs
                  class="warning"
                  :currentFormbuilderData="scope.row"
                  :allEntitiesData="allEntitiesData"
                  :allCompanyUserTypesData="allCompanyUserTypesData"
                  :usersLogs="
                      automationLogs[scope.row.automation_batch_data_id].logs
                    "
                ></automationFormbuilderLogs>
              </div>
              <div v-else class="warning d-flex">
                Pending &nbsp;
                <automationFormbuilderLogs
                  class="warning"
                  :currentFormbuilderData="scope.row"
                  :allEntitiesData="allEntitiesData"
                  :allCompanyUserTypesData="allCompanyUserTypesData"
                  :usersLogs="
                      automationLogs[scope.row.automation_batch_data_id].logs
                    "
                ></automationFormbuilderLogs>
              </div>
            </div>
            <div
              v-else-if="!scope.row.next_user_data"
              class="dull-text"
            >Not involved in the enforce order</div>
          </template>
        </el-table-column>
        <el-table-column prop="action" min-width="120">
          <template slot-scope="scope">
            <el-dropdown>
              <span class="el-dropdown-link">
                Actions
                <i class="el-icon-caret-bottom el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <a
                  @click="onHold(scope.row)"
                  v-if="
                    checkIsApproverUser(scope.row) &&
                    scope.row.approval_status == 'PENDING' &&
                    getIframeUrlInfo == null
                  "
                >
                  <el-dropdown-item icon="el-icon-video-pause" command="view"
                    >{{approvalHoldName ? approvalHoldName : 'HOLD'}}</el-dropdown-item
                  >
                </a>
                <a
                  @click="onApprove(scope.row)"
                  v-if="
                      checkIsApproverUser(scope.row) && getIframeUrlInfo == null
                    "
                >
                  <el-dropdown-item icon="el-icon-finished" command="view">Approve</el-dropdown-item>
                </a>
                <a
                  @click="showRejectConfirm(scope.row)"
                  v-if="
                      checkIsApproverUser(scope.row) && getIframeUrlInfo == null
                    "
                >
                  <el-dropdown-item icon="el-icon-circle-close" command="view">Reject</el-dropdown-item>
                </a>
                <a
                  v-if="
                      IframePermissions('VIEW', 'FormBuilderUserDate') &&
                      checkReceiverPermissions('view', scope.row)
                    "
                  @click="viewFormbuilderData(scope.row)"
                  style="border: none"
                >
                  <el-dropdown-item icon="el-icon-view" command="view">View</el-dropdown-item>
                </a>
                <a
                  v-if="
                      IframePermissions('EDIT', 'FormBuilderUserDate') &&
                      checkReceiverPermissions('edit', scope.row)
                    "
                  @click="editWorkUserData(scope.row)"
                  style="border: none"
                >
                  <el-dropdown-item icon="el-icon-edit-outline" command="edit">Edit</el-dropdown-item>
                </a>
                <a
                  v-if="
                      IframePermissions('DELETE', 'FormBuilderUserDate') &&
                      checkReceiverPermissions('delete', scope.row)
                    "
                  @click="onDelete(scope.row)"
                  style="border: none"
                >
                  <el-dropdown-item icon="el-icon-delete" command="delete">Delete</el-dropdown-item>
                </a>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>

        <el-table-column v-if="hasIntervals" label="Submitted For" min-width="150">
          <template slot-scope="scope">
            <div>
              <span class="black-coral sm-80" v-if="scope.row.time_interval">
                {{
                scope.row.time_interval
                | moment(fequencyDateMap[intervalType])
                }}
              </span>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          label="Approval Status"
          min-width="180"
          sortable="custom"
        >
          <template slot-scope="scope">
            <div class="success" v-if="scope.row.approval_status == 'APPROVED'">
              <el-popover
                placement="top-start"
                title="Approval details:"
                width="300"
                trigger="hover"
              >
                <div v-if="scope.row.priority_based">
                  <!-- In priority based approvals, only main approver details should be displayed -->
                  <span
                    v-if="
                        getApprovedUser(scope.row) &&
                        getApprovedUser(scope.row).approved_by
                      "
                  >
                    Approved by:
                    {{ getApprovedUser(scope.row).approved_by }}
                  </span>
                  <br />
                  <span
                    v-if="
                        getApprovedUser(scope.row) &&
                        getApprovedUser(scope.row).approved_at
                      "
                  >
                    Approved on:
                    {{
                    convertToLocalDateTime(
                    getApprovedUser(scope.row).approved_at
                    ) | globalDateTimeFormat
                    }}
                  </span>
                </div>
                <div v-else>
                  <!-- In non-priority based(all users need to approve) approvals, all approvers details should be displayed -->
                  <ul>
                    <li
                      v-for="approver in getApprovedUser(scope.row)"
                      :key="approver._id"
                      class="list-style"
                    >
                      <div v-if="approver.approved_by && approver.approved_at">
                        <i class="el-icon-user-solid icon-style"></i>
                        Approved by:
                        <span
                          class="approver-text"
                        >{{ " " + approver.approved_by + "," }}</span>
                        <br />
                        <i class="el-icon-time icon-style"></i>
                        Approved at:
                        <span class="approver-text">
                          {{
                          (" " +
                          convertToLocalDateTime(approver.approved_at))
                          | globalDateTimeFormat
                          }}
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
                <div slot="reference">
                  <i class="el-icon-success"></i>
                  <span class="d-inline-block pl-10">
                    {{
                    scope.row.approval_status == "APPROVED"
                    ? "Approved"
                    : ""
                    }}
                    <el-tooltip
                      trigger="hover"
                      effect="dark"
                      content="Revoke Approval"
                      placement="right"
                      v-if="scope.row.allow_revoke_approval &&
                        (scope.row.allow_revoke_for_approved || scope.row.allow_revoke_for_both) &&
                        scope.row.approval_status == 'APPROVED'"
                    >
                      <el-link
                        type="warning"
                        @click="showRevokeApprovalConfirm(scope.row)"
                        :underline="false"
                      >
                        <i class="fa fa-level-up"></i>
                      </el-link>
                    </el-tooltip>
                  </span>
                </div>
              </el-popover>
            </div>
            <div class="error" v-else-if="scope.row.approval_status == 'REJECTED'">
              <el-popover
                placement="top-start"
                title="Rejection details:"
                width="300"
                trigger="hover"
              >
                <div>
                  <span
                    v-if="
                        getRejectedUser(scope.row) &&
                        getRejectedUser(scope.row).rejected_by
                      "
                  >
                    Rejected by:
                    {{ getRejectedUser(scope.row).rejected_by }}
                  </span>
                  <br />
                  <span
                    v-if="
                        getRejectedUser(scope.row) &&
                        getRejectedUser(scope.row).rejected_at
                      "
                  >
                    Rejected on:
                    {{
                    convertToLocalDateTime(
                    getRejectedUser(scope.row).rejected_at
                    ) | globalDateTimeFormat
                    }}
                  </span>
                  <br />
                  <span
                    v-if="
                        getRejectedUser(scope.row) &&
                        getRejectedUser(scope.row).rejected_reason
                      "
                    style="word-break: break-word"
                  >
                    Reason:
                    {{ getRejectedUser(scope.row).rejected_reason }}
                  </span>
                </div>
                <div slot="reference" class="expired-div">
                  <i class="el-icon-error"></i>
                  <span class="d-inline-block pl-10">
                    {{
                    scope.row.approval_status == "REJECTED"
                    ? "Rejected"
                    : ""
                    }}
                    <el-tooltip
                      trigger="hover"
                      effect="dark"
                      content="Revoke Approval"
                      placement="right"
                      v-if="
                        scope.row.allow_revoke_approval &&
                        (scope.row.allow_revoke_for_rejected || scope.row.allow_revoke_for_both) &&
                        scope.row.approval_status == 'REJECTED'
                      "
                    >
                      <el-link
                        type="warning"
                        @click="showRevokeApprovalConfirm(scope.row)"
                        :underline="false"
                      >
                        <i class="fa fa-level-up"></i>
                      </el-link>
                    </el-tooltip>
                  </span>
                </div>
              </el-popover>
            </div>
            <div
              :class="
                  getReviewerData(scope.row) &&
                  getReviewerData(scope.row).status == 'REJECTED'
                    ? 'error'
                    : 'success'
                "
              v-else-if="checkReviewer(scope.row)"
            >
              <el-popover
                placement="top-start"
                :title="getReviewerData(scope.row).title"
                width="300"
                trigger="hover"
              >
                <div>
                  <span v-if="getReviewerData(scope.row).label1">
                    {{
                    getReviewerData(scope.row).label1
                    }}
                  </span>
                  <br />
                  <span v-if="getReviewerData(scope.row).date">
                    On:
                    {{
                    getReviewerData(scope.row).date | globalDateTimeFormat
                    }}
                  </span>
                  <br />
                  <span v-if="getReviewerData(scope.row).reason" style="word-break: break-word">
                    Reason:
                    {{ getReviewerData(scope.row).reason }}
                  </span>
                </div>
                <div slot="reference">
                  <i
                    :class="
                        getReviewerData(scope.row).status == 'REJECTED'
                          ? 'el-icon-error'
                          : 'el-icon-success'
                      "
                  ></i>
                  <span class="d-inline-block" style="word-break: break-word">
                    {{
                    getReviewerData(scope.row).status +
                    " by " +
                    getReviewerData(scope.row).user_name
                    }}
                  </span>
                </div>
              </el-popover>
            </div>
            <div
              class="warning d-flex"
              v-else-if="
                  scope.row.approval_users && scope.row.approval_users.length
                "
            >
              <!-- <i class="el-icon-time"></i> -->
              <span
                class="d-inline-block pl-10"
              >{{ scope.row.approval_status == "HOLD" ? approvalHoldName : "Pending" }}</span>
              <formbuilderLogs
                class="warning"
                :currentFormbuilderData="scope.row"
                :allEntitiesData="allEntitiesData"
                :allCompanyUserTypesData="allCompanyUserTypesData"
                v-on:reload-table="fetchAllEmployee"
              ></formbuilderLogs>
            </div>
            <div class="warning" v-else>
              <span class="d-inline-block pl-10">Not approval data</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="Sent Status" min-width="150" sortable="custom">
          <template slot-scope="scope">
            <div>
              <span v-if="scope.row.sent_at" class="d-block yale-blue sm-80 line-height-1">Sent On</span>
              <span
                class="black-coral sm-80"
                v-if="scope.row.sent_at"
              >{{ scope.row.sent_at | moment("MM-DD-YYYY HH:mm") }}</span>

              <span v-if="!scope.row.sent_at" class="d-block yale-blue sm-80 line-height-1">Not Send</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Completion Status" width="250" fixed="right" sortable="custom">
          <template slot-scope="scope">
            <div
              class="warning"
              v-if="
                  scope.row.formbuilder_data_status == 'PENDING' ||
                  scope.row.formbuilder_data_status == 'SENT'
                "
            >
              <i class="el-icon-refresh"></i>
              <span class="d-inline-block pl-10">
                {{
                scope.row.formbuilder_data_status == "PENDING" ||
                scope.row.formbuilder_data_status == "SENT"
                ? "Pending"
                : ""
                }}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <el-tooltip
                  :content="getContent(scope.row)"
                  placement="top"
                  v-if="!isAnonymousForm(scope.row)"
                >
                  <el-button
                    @click="resendForm(scope.row)"
                    type="primary"
                    size="mini"
                    class="resend-form-style"
                  >Resend</el-button>
                </el-tooltip>
              </span>
            </div>

            <div class="success" v-else-if="scope.row.formbuilder_data_status == 'COMPLETED'">
              <i class="el-icon-success"></i>
              <span class="d-inline-block pl-10">
                {{
                scope.row.formbuilder_data_status == "COMPLETED"
                ? "Completed"
                : ""
                }}
              </span>
            </div>

            <div class="expired-div" v-else-if="scope.row.formbuilder_data_status == 'EXPIRED'">
              <i class="el-icon-error"></i>
              <span class="d-inline-block pl-10">
                {{
                scope.row.formbuilder_data_status == "EXPIRED"
                ? "Expired"
                : ""
                }}
              </span>
            </div>

            <div v-else>Pending</div>
          </template>
        </el-table-column>
      </data-tables-server>
    </div>
    <dialog-component
      :destroy-on-close="true"
      :visible.sync="reasonForRejectModal"
      :containerWidth="getIsMobile ? '100%' : '40%'"
      :containerMaxHeight="getIsMobile ? '100%' : '70%'"
      title="Reason for reject"
      @before-close="reasonForRejectModaldialog"
    >
      <el-input
        type="textarea"
        :rows="2"
        placeholder="Reason for reject"
        v-model="reasonForReject"
        class="mt-1 mb-1"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="rejectFormbuilder(reasonForRejectData)"
          type="primary"
          :disabled="!reasonForReject.length"
          >Submit</el-button
        >
        <el-button @click="reasonForRejectModal = false">Cancel</el-button>
      </span>
    </dialog-component>
    <dialog-component
      :destroy-on-close="true"
      :visible.sync="showRevokeReasonDialog"
      :containerWidth="getIsMobile ? '100%' : '40%'"
      :containerMaxHeight="getIsMobile ? '100%' : '70%'"
      title="Reason for revoke approval"
      @before-close="closeRevokeDialog"
    >
      <div class="p-4">
        Are you sure to revoke the approval?
        Please mention your reason below:
        <el-input
          type="textarea"
          :rows="4"
          placeholder="Reason for revoke approval (Optional)"
          v-model="reasonForRevokeApproval"
          class="mt-1 mb-1"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="revokeApproval(reasonForRevokeApprovalData)"
          type="primary"
          >Revoke</el-button
        >
        <el-button @click="closeRevokeDialog">Cancel</el-button>
      </span>
    </dialog-component>
    
    <dialog-component
      :destroy-on-close="true"
      :visible.sync="showBulkRejectDialog"
      :containerWidth="getIsMobile ? '100%' : '40%'"
      :containerMaxHeight="getIsMobile ? '100%' : '70%'"
      title="Reason for rejection"
      @before-close="closeBulkRejectDialog"
    >
      <el-input
        type="textarea"
        :rows="2"
        placeholder="Reason for reject"
        v-model="reasonForBulkRejection"
        class="mt-1 mb-1"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="rejectSelctedFormData()"
          type="primary"
          :disabled="!reasonForBulkRejection.length"
          >Submit & Reject All</el-button
        >
        <el-button @click="closeBulkRejectDialog">Cancel</el-button>
      </span>
    </dialog-component>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import config from "@/config/app";

import FormbuilderHelper from "@/mixins/FormbuilderHelper.js";
import TemplateBuilderHelper from "@/mixins/templateBuilderHelper.js";
import NavigationHelper from "@/mixins/navigationHelper";
import CustomDashboardHelper from "../../mixins/CustomDashboardHelper";
import { postAPICall } from "@/helpers/httpHelper";

export default {
  name: "CustomDashboardFormbuilderUsersData",
  components: {
    formbuilderLogs: () => import("@/components/widgets/formbuilderLogsWidget"),
    automationFormbuilderLogs: () =>
      import("@/components/widgets/formbuilderAutomationLogs")
  },
  props: {
    componentData: Object
  },
  mixins: [
    FormbuilderHelper,
    TemplateBuilderHelper,
    NavigationHelper,
    CustomDashboardHelper
  ],
  data() {
    return {
      approvalFilter: [],
      formbuilderDataFilter: [],
      searchQuery: "",
      sentStatusFilter: "",
      customDateRange: null,
      adjustedDateRange: null,
      loading: false,
      editDailogVisible: false,
      selectedOfferLetterTemplate: "",
      total: 0,
      currentPage: 1,
      pageSize: 0,
      data: [],
      formbuilderDetails: {},
      formbuilder_code: "",
      loadingText: "Fetching formbuilder data, please wait...",
      automationLogs: {},
      resendLoading: false,
      isApprovalFormbuilder: false,
      selectedUserId: "",
      hasIntervals: false,
      intervalType: "",
      workFlowPublicURL: "",
      copyModal: false,
      url_key: "",
      checkRoutekey: "",
      previewDialogVisible: false,
      selectedRowsIds: [],
      allCompanyUserTypesData: [],
      allEntitiesData: [],
      approver: "",
      reasonForReject: "",
      reasonForRejectModal: false,
      reasonForRejectData: null,
      isApplicationUserSide: false,
      activeWorkspace: "",
      openStartFormbuilderModal: false,
      showRevokeReasonDialog: false,
      reasonForRevokeApproval: '',
      reasonForRevokeApprovalData: null,
      approvalHoldName: 'HOLD',
      showBulkRejectDialog: false,
      reasonForBulkRejection: '',
    };
  },
  computed: {
    ...mapGetters("formBuilders", [
      "getFormBuilderAddStatus",
      "getFormBuilder",
      "getFormBuilderRemindersSettings",
      "getFormBuilderUpdateStatus",
      "getFormBuilderPublicURLDetails"
    ]),
    ...mapGetters("formbuilderData", [
      "getFormbuilderUsersData",
      "getFormbuilderUniqueUsersData",
      "getFormbuilderCreateByContactData",
      "getFormbuilderCreateByContactStatus",
      "getFormbuilderCreateByContactErrors",
      "getFormbuilderDataDeleteStatus",
      "getFormbuilderDataDeleteError",
      "getFormbuilderDataUpdateStatus",
      "getFormbuilderDataUpdateError",
      "getFormbuilderDataBulkDeleteStatus",
      "getFormbuilderDataBulkDeleteErrors",
    ]),
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getActiveContactType",
      "getUserTypeList"
    ]),
    ...mapGetters("navigationOpen", ["getIsMobile"]),
    ...mapGetters("menuManagementV2", ["getEntityMenu"]),
    ...mapGetters("iFrame", ["getIframeUrlInfo"]),
    ...mapGetters("contacts", ["getAllContacts", "getCheckContactWithEmail"]),
    ...mapGetters("company", [
      "getCompanyDetails",
      "getContactLoginSlug"
    ]),
    paginationProps() {
      return {
        pageSizes: this.pageSizes || [10, 20, 50]
      };
    },
    getContacts() {
      return this.getAllContacts && this.getAllContacts.data
        ? this.getAllContacts.data
        : [];
    },
    getUserEmail() {
      return formbuilderData => {
        if (formbuilderData.contact) {
          return formbuilderData.contact && formbuilderData.contact.email
            ? formbuilderData.contact.email
            : "";
        }
        return "";
      };
    },
    getUserName() {
      return formbuilderData => {
        if (formbuilderData.contact) {
          return formbuilderData.contact &&
            formbuilderData.contact.first_name &&
            formbuilderData.contact.first_name
            ? formbuilderData.contact.first_name +
                " " +
                formbuilderData.contact.last_name
            : "";
        }
        return "";
      };
    },
    getContent() {
      return formbuilderData => {
        let name = this.getUserName(formbuilderData);
        let email = this.getUserEmail(formbuilderData);
        if (name && email) {
          return "Resend the form to " + name + " (" + email + ")";
        }
        return "Resend the form to the user";
      };
    }
  },
  async mounted() {
    let limit = (this.pageSizes && this.pageSizes[0]) || 10;
    this.pageSize = limit;
    this.loading = true;
    [this.allCompanyUserTypesData, this.allEntitiesData] = await Promise.all([
      this.fetchCompanyUsersTypes(),
      this.fetchAllEntities(),
    ]);
    this.loading = false;
    await this.getNecessaryInfo();
  },
  methods: {
    async approveAllChecked() {
      this.$confirm(
        "Are you sure to approve the selected form responses?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.approveSelectedFormData();
      });
    },
    //Bulk Approve form responses
    async approveSelectedFormData() {
      this.loading = true;
      this.loadingText = 'Approving the form responses, please wait...';
      let params = [];
      this.selectedRowsIds.forEach((item, i) => {
        if (item && item._id && (item.approval_status == 'PENDING' || item.approval_status == 'HOLD')) {
          let currentuserIndex = this.getCurrentApprover(item);
          if (currentuserIndex > -1) {
            let approvalData = this.getApprovalParams(item, currentuserIndex);
            params.push({
              id: item._id,
              approval_data: approvalData
            });
          }
        } else {
          i = i+1;
          if (i == this.selectedRowsIds.length) {
            this.$message({
              message: `${i} form(s) are already ${item.approval_status}`,
              type: "warning",
            });
          }
          this.loading = false;
        }
      });
      if (params.length) {
        const response = await postAPICall(
          "POST",
          "/formbuilder-data/bulk-approval-actions",
          params
        );
        if (response.success) {
          this.$notify.success({
            title: "Success",
            message: "Form responses approved successfully",
          });
          this.fetchAllEmployee();
        }
      }
      this.loading = false;
      this.loadingText = 'Fetching data, please wait...';
    },
    showBulkRejectConfirm() {
      this.showBulkRejectDialog = true;
      this.showBulkRejectReason = '';
    },
    closeBulkRejectDialog() {
      this.showBulkRejectDialog = false;
      this.showBulkRejectReason = '';
    },
    //Bulk reject form responses
    async rejectSelctedFormData() {
      this.closeBulkRejectDialog();
      this.loading = true;
      this.loadingText = 'Rejecting the form responses, please wait...'
      let params = [];
      this.selectedRowsIds.forEach((item, i) => {
        if (item && item._id && (item.approval_status == 'PENDING' || item.approval_status == 'HOLD')) {
          let currentuserIndex = this.getCurrentApprover(item);
          if (currentuserIndex > -1) {
            let approvalData = this.getRejectParams(
              item,
              currentuserIndex,
              this.reasonForBulkRejection
            );
            params.push({
              id: item._id,
              approval_data: approvalData
            });
          }
        } else {
          i = i+1;
          if (i == this.selectedRowsIds.length) {
            this.$message({
              message: `${i} form(s) are already ${item.approval_status}`,
              type: "warning",
            });
          }
          this.loading = false;
        }
      });
      if (params.length) {
        const response = await postAPICall(
          "POST",
          "/formbuilder-data/bulk-approval-actions",
          params
        );
        if (response.success) {
          this.$notify.success({
            title: "Success",
            message: "Form responses rejected successfully",
          });
          this.fetchAllEmployee();
        }
      }
      this.loading = false;
      this.loadingText = 'Fetching data, please wait...';
    },
    async holdAllChecked() {
      this.$confirm(
        "Are you sure to hold the selected form responses?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.holdSelctedFormData();
      });
    },
    async holdSelctedFormData() {
      this.loading = true;
      this.loadingText = 'Holding the form responses, please wait...'
      let params = [];
      let approvalData = {
        approval_status: 'HOLD'
      }
      this.selectedRowsIds.forEach((item, i) => {
        if (item && item._id && item.approval_status == 'PENDING') {
          let currentuserIndex = this.getCurrentApprover(item);
          if (currentuserIndex > -1) {
            params.push({
              id: item._id,
              approval_data: approvalData
            });
          }
        } else {
          i = i+1;
          if (i == this.selectedRowsIds.length) {
            this.$message({
              message: `${i} form(s) are already ${item.approval_status}`,
              type: "warning",
            });
          }
          this.loading = false;
        }
      });
      if (params.length) {
        const response = await postAPICall(
          "POST",
          "/formbuilder-data/bulk-approval-actions",
          params
        );
        if (response.success) {
          this.$notify.success({
            title: "Success",
            message: `Form responses ${this.approvalHoldName} successfully`,
          });
          this.fetchAllEmployee();
        }
      }
      this.loading = false;
      this.loadingText = 'Fetching data, please wait...';
    },
    async onHold(row) {
      this.$confirm("Are you sure to hold the response?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.holdFormbuilder(row);
      });
    },
    async holdFormbuilder(formbuilderData) {
      this.loading = true;
      let currentuserIndex = this.getCurrentApprover(formbuilderData);
      if (currentuserIndex > -1) {
        await this.$store.dispatch("formbuilderData/updateFormbuilderData", {
          approval_status: "HOLD",
          id: formbuilderData._id,
        });
        if (this.getFormbuilderDataUpdateStatus) {
          this.$message({
            message: `Form state updated to ${this.approvalHoldName} successfully!`,
            type: "success",
          });
          this.fetchAllEmployee();
        } else {
          this.$notify.warning({
            title: "Warning",
            message:
              this.getFormbuilderDataUpdateError.message ||
              "Error while Form state updated to hold",
          });
          this.fetchAllEmployee();
        }
      } else {
        this.$notify.warning({
          title: "Warning",
          message: "You are not approver",
        });
      }
      this.loading = false;
    },
    convertToLocalDateTime(dateTime) {
      const date = new Date(dateTime);
      return date.toLocaleString();
    },
    getApprovedUser(formbuilderData) {
      // In priority based approvals, only main approver details should be displayed
      if (formbuilderData?.approval_users && formbuilderData?.priority_based) {
        return formbuilderData?.approval_users.find(
          e =>
            (!formbuilderData.priority_based && e.approved_status) ||
            (formbuilderData.priority_based &&
              e.approved_status &&
              e.final_approver)
        );
      } else {
        // In non-priority based(all users need to approve) approvals, all approvers details should be displayed, so returning all the approvers
        return formbuilderData?.approval_users;
      }
    },
    getRejectedUser(formbuilderData) {
      if (formbuilderData?.approval_users) {
        return formbuilderData?.approval_users.find(
          e =>
            (!formbuilderData.priority_based && e.rejected_status) ||
            (formbuilderData.priority_based &&
              e.rejected_status &&
              e.final_approver)
        );
      }
      return null;
    },
    checkIsApproverUser(formbuilderData) {
      if (
        formbuilderData?.approval_users &&
        formbuilderData?.approval_status != "APPROVED" &&
        formbuilderData?.approval_status != "REJECTED"
      ) {
        let currentuserIndex = this.getCurrentApprover(formbuilderData);
        if (
          currentuserIndex > -1 &&
          !formbuilderData.approval_users[currentuserIndex].approved_status &&
          !formbuilderData.approval_users[currentuserIndex].rejected_status
        ) {
          return formbuilderData.formbuilder_data_status == "COMPLETED";
        }
        // if (formbuilderData?.priority_based == true && formbuilderData?.approval_status == 'PENDING') {
        //   let foundUser = formbuilderData.approval_users.findIndex(e => 
        //     (e.approved_by && (e.approved_by !== this.getAuthenticatedUser?.email)) ||
        //      (e.rejected_by && (e.rejected_by !== this.getAuthenticatedUser?.email)));
        //   if (foundUser > -1) {
        //     return true;
        //   }
        //   return false;
        // }
        return false;
      }
      return false;
    },
    isAnonymousForm(formbuilderData) {
      return (
        formbuilderData?.formbuilders_details_id?.form_builders_owner ===
        "ANONYMOUS"
      );
    },
    async loadData(data) {
      if (this.data && this.data.length) {
        this.pageSize = data.pageSize;
        this.page = data.page;
        await this.fetchAllEmployee();
      }
    },
    async getNecessaryInfo() {
      await this.fetchAllEmployee();
      this.approvalHoldName = this.getCompanyDetails?.formbuilder_approval_hold_name ? this.getCompanyDetails.formbuilder_approval_hold_name : 
                              this.getContactLoginSlug?.formbuilder_approval_hold_name ? this.getContactLoginSlug.formbuilder_approval_hold_name : 'HOLD';
    },
    async fetchAllEmployee() {
      this.loading = true;
      this.data = [];
      let formbuilderIds = [];
      if (this.componentData?.selected_formbuilder_ids?.[0] == 'ALL') {
        formbuilderIds = [];
      } else if (this.componentData?.selected_formbuilder_ids) {
        formbuilderIds = this.componentData?.selected_formbuilder_ids.map(item => item.split('#')[0]);
      }
      if (this.componentData?.formbuilder_filter?.includes("APPROVAL")) {
        let filter = this.componentData?.formbuilder_filter?.split("_")?.[1];
        this.approvalFilter.push(filter);
      } else if (this.componentData?.formbuilder_filter?.includes("FORM")) {
        let filter = this.componentData?.formbuilder_filter?.split("_")?.[1];
        this.formbuilderDataFilter.push(filter);
      }
      let params = {
        page: this.currentPage,
        limit: this.pageSize,
        formbuilder_details_ids: formbuilderIds,
        include_template_details: false,
        include_user_details: true,
        include_formbuilder_details: true,
        approval_status: this.approvalFilter,
        formbuilder_data_status: this.formbuilderDataFilter,
        searchQuery: this.searchQuery
      };

      if (this.sentStatusFilter) {
        if (this.sentStatusFilter === "custom_date_range" && this.adjustedDateRange) {
          params["sent_at"] = this.adjustedDateRange;
        }else {
          const dateFilter = this.getDatePickerHelper1(
            this.sentStatusFilter,
            new Date()
          );
          params["sent_at"] = dateFilter.value;
        }
      }
      params["is_filled_by_login_user"] = this.componentData?.formbuilder_user_filter == "FILLED_BY_ME" ? true : false;
      await this.$store.dispatch(
        "formbuilderData/fetchUsersFormbuilderData",
        params
      );
      this.loading = false;
      this.currentPage = parseInt(this.getFormbuilderUsersData.page) || 1;
      this.total = parseInt(this.getFormbuilderUsersData.total) || 0;

      if (
        this.getFormbuilderUsersData.data &&
        this.getFormbuilderUsersData.data.length
      ) {
        this.data = this.getFormbuilderUsersData.data;
        await this.getEnforceOrderLogs(this.data);
        // this.extractEmails(this.data);
        // this.extractCompanies(this.data);
      } else {
        this.data = [];
      }
    },
    showRevokeApprovalConfirm(formbuilderData) {
      this.reasonForRevokeApprovalData = null;
      this.reasonForRevokeApproval = "";
      this.showRevokeReasonDialog = true;
      this.reasonForRevokeApprovalData = { ...formbuilderData };
    },
    closeRevokeDialog() {
      this.showRevokeReasonDialog = false;
    },
    async revokeApproval(data) {
      try {
        this.showRevokeReasonDialog = false;
        this.loading = true;
        this.loadingText = "Revoking approval, please wait...";
        if (data?.allow_revoke_approval && ["APPROVED","REJECTED"].includes(data.approval_status)) {
          const response = await postAPICall(
            "POST",
            "/formbuilder-data/revoke-approval",
            {
              formbuilder_details_id: data.formbuilders_details_id,
              formbuilder_data_id: data._id,
              revoke_reason: this.reasonForRevokeApproval,
              revoked_by:
                this.getAuthenticatedUser?.first_name +
                " " +
                this.getAuthenticatedUser?.last_name +
                "#" +
                this.getAuthenticatedUser?.email
            }
          );
          if (response) {
            this.$notify.success({
              title: "Success",
              message: "Approval revoked successfully"
            });
            this.fetchAllEmployee();
            this.loading = false;
          }
          this.loading = false;
          this.loadingText = "";
          this.reasonForRevokeApprovalData = null;
          this.reasonForRevokeApproval = "";
        }
      } catch (err) {
        console.log("error while revoking approval", err);
      }
    },
    async openNotificationsDialog(paramsData) {
      this.paramsData = paramsData;
      this.notificationEmails = this.selectedRowsIds
        .map(item => {
          if (item && item.contact && item.contact.email) {
            return item.contact.email;
          }
          return null;
        })
        .filter(email => email);
      if (this.notificationEmails.length > 0) {
        this.sendNotificationDialog = true;
      } else {
        this.$notify.error({
          title: "Error",
          message: "Please select user emails.",
          type: "error"
        });
      }
    },
    async closeSendNotificationDialog(params) {
      this.emailConfigLoading = true;
      params.company_id = this.getFormBuilder.company;
      let endPoint = "/entities-data/users/send-notification";
      let response = await postAPICall("POST", endPoint, params);
      if (response) {
        this.emailConfigLoading = false;
        this.$notify({
          title: "Success",
          message: "Sending mail(s) initiated",
          type: "success"
        });
      } else {
        this.emailConfigLoading = false;
        this.$notify({
          title: "Error",
          message: "Error while sending mails",
          type: "error"
        });
      }
      this.sendNotificationDialog = false;
    },
    getAllEmails() {
      if (Array.isArray(this.getFormBuilder)) {
        return this.getFormBuilder.map(
          formBuilder => formBuilder.contact.email
        );
      }
      return [];
    },
    async onSentStatusChange() {
      if (this.sentStatusFilter !== "custom_date_range") {
        this.customDateRange = null;
        await this.fetchAllEmployee();
      }
    },

    onDateRangeChange() {
      if (
        this.customDateRange &&
        this.sentStatusFilter === "custom_date_range"
      ) {
        const startDate = this.customDateRange[0];
        let endDate = new Date(this.customDateRange[1]);
        endDate.setDate(endDate.getDate() + 1);
        this.adjustedDateRange = [startDate, endDate];
        this.fetchAllEmployee();
      }
    },
    async resendForm(formbuilderData) {
      this.resendLoading = true;
      let response;
      let params = {
        formbuilder_data_id: formbuilderData._id,
        formbuilders_details_id: formbuilderData.formbuilders_details_id,
        contact: formbuilderData.contact,
        company_id: formbuilderData.company
      };
      try {
        response = await postAPICall(
          "POST",
          "/formbuilder-data/resend-form",
          params
        );
        if (response.success && response.data) {
          this.$notify.success({
            title: "Success",
            message: "Form sent to user email successfully"
          });
        }
        this.resendLoading = false;
      } catch (err) {
        console.log("error while sending the form :", err);
      }
      this.resendLoading = false;
    },
    checkReceiverPermissions(access, data) {
      // If form is approved, whether need to give permissions to view, edit, delete submission for receiver(application user or company user)
      if (
        (this.$route?.name == "applicationFormBuilderUserData" ||
          this.$route?.query?.routeType == "FB") &&
        data.approval_status == "APPROVED"
      ) {
        if (this.formbuilderDetails?.approval_receiver_permissions) {
          let permissions = this.formbuilderDetails
            .approval_receiver_permissions;
          if (access == "view" && permissions.view == true) {
            return true;
          } else if (access == "edit" && permissions.edit == true) {
            return true;
          } else if (access == "delete" && permissions.delete == true) {
            return true;
          } else {
            return false;
          }
        }
      } else {
        return true;
      }
    },
    reasonForRejectModaldialog() {
      this.reasonForRejectModal = false;
    },
    handleSelectionChange(checkedRows) {
      this.selectedRowsIds = checkedRows;
    },
    async deleteChecked() {
      this.$confirm(
        "Are you sure to delete the form response data?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      ).then(() => {
        this.deleteSelectedFormData();
      });
    },
    //Bulk delete form response data
    async deleteSelectedFormData() {
      let selectedIds = [];
      this.selectedRowsIds.forEach(item => {
        if (item && item._id) {
          selectedIds.push(item._id);
        }
      });
      let params = {
        selectedIds: selectedIds,
        formbuilder_details_id: this.getFormBuilder._id
      };
      this.loading = true;
      await this.$store.dispatch(
        "formbuilderData/formBuilderDataBulkDelete",
        params
      );
      if (this.getFormbuilderDataBulkDeleteStatus) {
        this.loading = false;
        this.$notify.success({
          title: "Success",
          message: "Form data deleted successfully"
        });
        this.fetchAllEmployee();
      } else {
        let message = "Error while deleting data";
        if (this.getFormbuilderDataBulkDeleteErrors) {
          message = this.getFormbuilderDataBulkDeleteErrors;
        }
        this.loading = false;
        this.$notify.error({
          title: "Error",
          message: message
        });
      }
    },
    async setRouteConfiguration() {
      if (this.$route?.name == "applicationFormBuilderUserData") {
        if (this.getActiveContactType?.contact_type?._id) {
          this.activeWorkspace = this.getActiveContactType.contact_type._id;
        }
        if (this.$route?.params?.menuId || this.$route?.query?.menuId) {
          this.isApplicationUserSide = true;
          // await Promise.all([
          //   this.$store.dispatch(
          //     "applicationUsersPermission/fetchPermissionsEntityById",
          //     { id: this.getActiveContactType.contact_type._id }
          //   ),
          //   this.$store.dispatch(
          //     "menuManagementV2/fetchMenuByEntity",
          //     this.activeWorkspace
          //   ),
          // ]);
        }
      }
    },
    async onReject(row) {
      this.$confirm("Are you sure to reject the response?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning"
      }).then(() => {
        this.rejectFormbuilder(row);
      });
    },
    showRejectConfirm(formbuilderData) {
      this.reasonForRejectData = null;
      this.reasonForReject = "";
      this.reasonForRejectModal = true;
      this.reasonForRejectData = { ...formbuilderData };
    },
    async rejectFormbuilder(formbuilderData) {
      this.loading = true;
      this.reasonForRejectModal = false;
      this.loadingText = "Rejecting the form, please wait...";
      let currentuserIndex = this.getCurrentApprover(formbuilderData);
      if (currentuserIndex > -1) {
        let params = this.getRejectParams(
          formbuilderData,
          currentuserIndex,
          this.reasonForReject
        );
        await this.$store.dispatch(
          "formbuilderData/updateFormbuilderData",
          params
        );
        if (this.getFormbuilderDataUpdateStatus) {
          this.$message({
            message: "Rejected successfully!",
            type: "success"
          });
          this.reasonForRejectData = null;
          this.reasonForReject = "";
          this.fetchAllEmployee();
        } else {
          this.$notify.warning({
            title: "Warning",
            message:
              this.getFormbuilderDataUpdateError.message ||
              "Error while approving"
          });
          this.fetchAllEmployee();
        }
      } else {
        this.$notify.warning({
          title: "Warning",
          message: "You are not approver"
        });
      }
      this.loadingText = "";
      this.loading = false;
    },
    async onApprove(row) {
      this.$confirm("Are you sure to approve the response?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning"
      }).then(() => {
        this.approveFormbuilder(row);
      });
    },
    async approveFormbuilder(formbuilderData) {
      this.loading = true;
      this.loadingText = "Approving the form, please wait...";
      let currentuserIndex = this.getCurrentApprover(formbuilderData);
      if (currentuserIndex > -1) {
        let params = this.getApprovalParams(formbuilderData, currentuserIndex);
        await this.$store.dispatch(
          "formbuilderData/updateFormbuilderData",
          params
        );
        if (this.getFormbuilderDataUpdateStatus) {
          this.$message({
            message: "Approved successfully!",
            type: "success"
          });
          this.fetchAllEmployee();
        } else {
          this.$notify.warning({
            title: "Warning",
            message:
              this.getFormbuilderDataUpdateError.message ||
              "Error while approving"
          });
          this.fetchAllEmployee();
        }
      } else {
        this.$notify.warning({
          title: "Warning",
          message: "You are not approver"
        });
      }
      this.loadingText = "";
      this.loading = false;
    },
    async startFormbuilder() {
      this.openStartFormbuilderModal = false;
      // Redirecting to preview form to fill the form by owner/sender if there is only one receiver
      let url = config.APP_URL;
      url += `/owner/fb/${this.formbuilderDetails.code}/${this.formbuilderDetails.steps[0].slug}/${this.formbuilderDetails._id}?user_id=${this.getAuthenticatedUser._id}`;
      window.open(url, "_blank");
      let params = this.prepareParams();
      await this.$store.dispatch(
        "formbuilderData/createFormbuilderDataByContact",
        params
      );
      if (this.getFormbuilderCreateByContactStatus) {
        this.$message({
          message: "Form data created successfully!",
          type: "success"
        });
        //this is old form view
        // if (
        //   this.getFormbuilderCreateByContactData &&
        //   this.getFormbuilderCreateByContactData._id
        // ) {
        //   this.$router.push({
        //     name: "FormbuilderEdit",
        //     params: {
        //       formbuilder_code: this.formbuilder_code,
        //       formbuilder_data_id: this.getFormbuilderCreateByContactData._id,
        //     },
        //   });
        // }
      } else {
        this.$notify.error({
          title: "Error",
          message:
            this.getFormbuilderCreateByContactErrors &&
            this.getFormbuilderCreateByContactErrors.critical_error
              ? this.getFormbuilderCreateByContactErrors.critical_error
              : "Something went wrong"
        });
      }
    },
    prepareParams() {
      return {
        formbuilders_details_id: this.formbuilderDetails._id,
        contact: this.formbuilderReciever,
        formbuilder_steps_data: this.getFormbuilderSteps
      };
    },
    async openStartFormbuilder() {
      if (
        this.isApplicationUserSide &&
        this.formbuilderDetails?.form_builders_owner == "ENTITY" &&
        this.formbuilderDetails?.entity_id == this.activeWorkspace
      ) {
        let url = config.APP_URL;
        url += `/ap/fbd/${this.formbuilderDetails.code}/${this.formbuilderDetails.steps[0].slug}/${this.formbuilderDetails._id}?user_id=${this.getAuthenticatedUser._id}`;
        window.open(url, "_blank");
        return;
      }
      if (
        !this.isApplicationUserSide &&
        this.formbuilderDetails?.form_builders_owner == "USER" &&
        this.formbuilderDetails?.user_type.includes(
          this.getActiveWorkspace.user_type
        )
      ) {
        this.loading = true;
        await this.$store.dispatch("contacts/fetchContactByEmail", {
          email: this.getAuthenticatedUser.email,
          company_id: this.formbuilderDetails.company
        });
        if (this.getCheckContactWithEmail._id) {
          let url = config.APP_URL;

          url += `/public/fbd/${this.formbuilderDetails.code}/${this.formbuilderDetails.steps[0].slug}/${this.formbuilderDetails._id}?user_id=${this.getCheckContactWithEmail._id}`;

          window.open(url, "_blank");
          this.loading = false;
          return;
        } else {
          this.loading = false;
        }
      } else {
        this.loading = true;
        let params = {
          get_all: true,
          type: "CONTACT"
        };
        if (
          this.formbuilderDetails?.form_builders_owner == "ENTITY" &&
          this.formbuilderDetails?.entity_id
        ) {
          params = {
            ...params,
            ...{ contact_type: this.formbuilderDetails.entity_id }
          };
        } else if (
          this.formbuilderDetails?.form_builders_owner === "USER" &&
          this.formbuilderDetails?.user_type
        ) {
          await this.$store.dispatch(
            "auth/getCompanyUsersList",
            this.formbuilderDetails.company
          );
          if (this.getCompanyUsers?.data) {
            this.companyUsers = this.getCompanyUsers.data.filter(user => {
              if (user?.rolesList) {
                let existed = user.rolesList.find(
                  role =>
                    role &&
                    role.userType &&
                    this.formbuilderDetails.user_type.includes(
                      role.userType._id || role.userType
                    )
                );
                return existed ? true : false;
              }
            });
          }
        }
        await this.$store.dispatch("contacts/fetchAllContacts", params);
        if (this.getAllContacts?.data || this.getCompanyUsers) {
          this.loading = false;
          this.openStartFormbuilderModal = true;
        }
      }
    },
    printScope(r) {
      console.log(r);
    },

    onEdit(row) {
      this.selectedOfferLetterTemplate = row;
      this.editDailogVisible = true;
    },
    async onView(row) {
      this.selectedOfferLetterTemplate = row;
      this.previewDialogVisible = true;
    },
    async onDelete(row) {
      this.$confirm("Are you sure to delete the response?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning"
      }).then(() => {
        this.deleteTemplate(row);
      });
    },
    async deleteTemplate(row) {
      //deleteFormbuilderData
      await this.$store.dispatch(
        "formbuilderData/deleteFormbuilderData",
        row._id
      );
      if (this.getFormbuilderDataDeleteStatus) {
        this.$message({
          message: "Response deleted successfully!",
          type: "success"
        });
        this.fetchAllEmployee();
      } else {
        this.$notify.error({
          title: "Error",
          message: "Something went wrong. Can't delete."
        });
      }
    },

    async updateUserQuery(value) {
      this.$router.replace({
        name: this.$route.name,
        params: this.$route.params,
        query: { ...this.$route.query, user_id: value }
      });

      await this.getNecessaryInfo();
    },
    extractEmails(usersData) {
      this.notificationEmails = usersData
        .map(user => (user.contact ? user.contact.email : null))
        .filter(email => email);
    },
    extractCompanies(usersData) {
      this.companyIds = usersData
        .map(user => (user.company ? user.company : null)) // Extract company ID
        .filter(company => company); // Filter out null values
    },
    async getEnforceOrderLogs(data) {
      let automation_batch_data_ids = data
        .filter(d => d.next_user_data && d.automation_batch_data_id)
        .map(d => d.automation_batch_data_id);

      const response = await postAPICall(
        "POST",
        "/formbuilder-data/enforce-order-logs",
        {
          automation_batch_data_ids
        }
      );
      (response.data || []).forEach(e => {
        let data = {};
        let currentUser = (e.data || []).find(f => f.status == "SENT");
        let isCompleted = (e.data || []).every(f => f.status == "COMPLETED");
        data["currentUser"] = currentUser;
        data["logs"] = e.data || [];
        data["status"] = isCompleted ? "COMPLETED" : "PENDING";
        this.$set(this.automationLogs, e._id, data);
      });
    },

    backToWorkFlows() {
      let UrlPathAdd = "";
      if (this.getIframeUrlInfo != null) {
        UrlPathAdd = `/if/${this.getIframeUrlInfo._id}/formBuilders`;
      } else {
        UrlPathAdd = "/formBuilders";
      }
      this.$router.push({ path: UrlPathAdd, query: this.$route.query });
    },
    CloseStartFormbuilder() {
      this.openStartFormbuilderModal = false;
    },
    parseFirstTemplateData(data) {
      let modifiedData = data.map(e => {
        let dataObject = {
          _id: e._id,
          name: e.name,
          code: e.code,
          formbuilder_data_status: e.formbuilder_data_status,
          workflow_data_sender_status: e.workflow_data_sender_status
        };

        let firstTemplateData = e.template_data_ids.find(
          x => x.template_id._id == this.firstTemplateId
        );

        if (firstTemplateData) {
          let firstTemplateValues =
            firstTemplateData.template_data_id &&
            firstTemplateData.template_data_id.template_data
              ? firstTemplateData.template_data_id.template_data
              : {};
          dataObject.template_data = firstTemplateValues;
        } else {
          dataObject.template_data = {};
        }

        return dataObject;
      });
      return modifiedData;
    },
    async fetchAllConfigurableDocuments() {
      this.documentsLoading = true;
      await this.$store.dispatch(
        "configurableDocuments/fetchConfigurableDocuments",
        {
          get_all: true,
          type: "FORM"
        }
      );
      this.documentsLoading = false;
    },
    copyURL(url) {
      navigator.clipboard.writeText(url);
      this.$message({
        message: "Link copied successfully!",
        type: "success"
      });
    },

    async generateFormbuilderPublicURL() {
      try {
        this.loading = true;
        await this.$store.dispatch("formBuilders/fetchWorkFlowPublicURL", {
          formbuilder_details_id: this.getFormBuilder._id
        });
        if (this.getFormBuilder?.customization?.show_link_to_be_copy) {
          this.workFlowPublicURL = this.getFormBuilderPublicURLDetails.public_url;
        }
        this.false = true;
      } catch (err) {
        console.log("generateFormbuilderPublicURL", err);
      }
    },
    async selectDocuemnt(doc) {
      this.selectedConfigurableDocument = doc;
    },
    async viewFormbuilderData(row) {
      if (this.$route.name == "applicationFormBuilderUserData" || this.$route.name == "application-user-dashboard") {
        await this.$store.commit(
          "formBuilders/setFormBuilderPrevRouter",
          window.location.href,
          {
            root: true
          }
        );
        this.$router.push({
          name: "ApplicationUserFormbuilderView",
          params: {
            formbuilder_code: row.formbuilders_details_id.code,
            formbuilder_data_id: row._id
          },
          query: {
            isFromCustomDashboard: true,
          }
        });
      } else {
        await this.$store.commit(
          "formBuilders/setFormBuilderPrevRouter",
          window.location.href,
          {
            root: true
          }
        );
        let urlName = "";
        if (this.getIframeUrlInfo != null) {
          urlName = "FormbuilderViewSSO_External";
        } else {
          urlName = "FormbuilderView";
        }
        this.$router.push({
          name: urlName,
          params: {
            formbuilder_code: row.formbuilders_details_id.code,
            formbuilder_data_id: row._id
          },
          query: {
            isFromCustomDashboard: true,
          }
        });
      }
    },
    async editWorkUserData(row) {
      if (this.$route.name == "applicationFormBuilderUserData"  || this.$route.name == "application-user-dashboard") {
        await this.$store.commit(
          "formBuilders/setFormBuilderPrevRouter",
          window.location.href,
          {
            root: true
          }
        );
        this.$router.push({
          name: "ApplicationUserFormbuilderEdit",
          params: {
            formbuilder_code: row.formbuilders_details_id.code,
            formbuilder_data_id: row._id
          },
          query: {
            origin: "cwdjkrni"
          }
        });
      } else {
        let urlName = "";
        if (this.getIframeUrlInfo != null) {
          urlName = "FormbuilderEditSSO_External";
        } else {
          urlName = "FormbuilderEdit";
        }
        this.$router.push({
          name: urlName,
          params: {
            formbuilder_code: row.formbuilders_details_id.code,
            formbuilder_data_id: row._id
          }
        });
      }
    },
    getFlowName() {
      return this.getFormBuilder?.name;
    },

    getFieldData(templateData, field) {
      if (templateData[field.key]) {
        if (field.input_type == "DATE") {
          return this.$moment.utc(templateData[field.key]).format("MM-DD-YYYY");
        }
        return templateData[field.key];
      }
      return "-";
    },
    openViewConfigPage() {
      (this.url_key = this.$route.fullPath), (this.copyModal = true);
    }
  }
};
</script>

<style lang="scss" scoped>
.approver-text {
  color: #3366cc;
}
.list-style {
  margin-top: 10px;
}
.expired-div {
  color: #dc3545;
}
.dull-text {
  color: #888;
  opacity: 0.8;
  overflow-wrap: break-word;
  word-break: normal;
}
.log-expired {
  color: #dc3545;
}
.resend-form-style {
  width: 70px;
  height: 25px;
}
</style>